main {
  background-color: var(--color-background);
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.no-favorites-header {
  font-size: 0, 5, rem;
  margin-bottom: 1, rem;
  color: var(--color-txt);
  margin-top: 3rem;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.no-favorites-header-image {
  width: 24rem;
  height: 24rem;
  margin-bottom: 3rem;
}

.no-favorites-button {
  background-color: var(--color-btn-nofavorites);
  color: var(--color-white);
  border: none;
  border-radius: 18px;
  font-size: 18px;
  padding: 5px 20px;
}

.no-favorites-button:hover,
.no-favorites-button:focus {
  background-color: var(--color-btn-nofavorites-hover);
}

.no-favorites-button:active,
.no-favorites-button:visited {
  background-color: var(--color-btn-nofavorites-active) !important;
  transform: scale(0.98);
}
