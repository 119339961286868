.favoriteButton {
  background-color: var(--color-btn);
  color: var(--color-white);
  border: none;
}

.favoriteButton:hover,
.favoriteButton:focus {
  background-color: var(--color-btn-hover);
  color: var(--color-white);
}

.favoriteButton:active,
.favoriteButton:visited {
  background-color: var(--color-btn-active) !important;
  outline: none;
  color: var(--color-white) !important;
  transform: scale(0.98);
}

.favorite {
  background-color: var(--color-action-btn);
  color: var(--color-white);
  border: none;
}
.favorite:hover,
.favorite:focus {
  background-color: var(--color-action-btn-hover);
  color: var(--color-white);
}
.favorite:active,
.favorite:visited {
  background-color: var(--color-action-btn-active) !important;
  outline: none;
  color: var(--color-white) !important;
  transform: scale(0.98);
}

.adoptButton {
  background-color: var(--color-action-btn);
  color: var(--color-white);
  border: none;
}

.adoptButton:hover,
.adoptButton:focus {
  background-color: var(--color-action-btn-hover);
  color: var(--color-white);
}

.adoptButton:active,
.adoptButton:visited {
  background-color: var(--color-action-btn-active) !important;
  outline: none;
  color: var(--color-white) !important;
  transform: scale(0.98);
}

.buttonContainer {
  display: flex;
  justify-content: normal;
  align-items: center;
}

.buttonSpacing {
  margin: 7px;
}

.frame-border {
  border: 5px solid pink;
}
.frame-body {
  padding: 1rem 2rem 2rem 2rem;
}
.back-button {
  margin-left: 1.2rem;
}
