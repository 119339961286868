/* Desktop Design*/
.logo_wrapper {
  padding-inline: 1rem 3rem;
}

.logo {
  width: 4rem;  
  height: 4rem;  
  text-align: center;  
  /* padding: 15px;  
  border: 3px solid red;  */
}

.logo  img {
  /* object-fit: scale-down; */
  height: 100%;
}

.navLinkHeader,
.navLinkLogInHeader,
.navLinkLogOutHeader {
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  display: inline-block;
  position: relative;
}
.visited .navLinkHeader,
.visited .navLinkLogInHeader {
  color: var(--color-white);
}

.searchButton {
  border: 2px;
  text-align: center;
  border-radius: 50px;
  position: relative;
  right: 31px;
  padding-top: 1px;
  padding-bottom: 4px;
  padding-inline: 5px;
  background-color: var(--color-action-btn);
  box-shadow: 0 2px 5px var(--color-gray);
}
.searchButton:hover,
.searchButton:focus {
  border: 2px;
  cursor: pointer;
  text-align: center;
  border-radius: 50px;
  position: relative;
  right: 31px;
  padding-top: 1px;
  padding-bottom: 4px;
  padding-inline: 5px;
  background-color: var(--color-action-btn-active);
  box-shadow: 0 2px 5px var(--color-gray);
}
.searchButton:active {
  border: 2px;
  cursor: pointer;
  text-align: center;
  border-radius: 50px;
  position: relative;
  background-color: var(--color-action-btn) !important;
  box-shadow: 0 2px 5px var(--color-gray);
}

.color-btn {
  background-color: var(--color-btn);
  color: var(--color-white);
}
.color-btn:hover,
.color-btn:focus {
  background-color: var(--color-btn-hover);
  color: var(--color-white);
}

.color-btn:active,
.color-btn:visited {
  transform: scale(0.98);
  background-color: var(--color-btn-hover) !important;
  color: var(--color-white) !important;
}

.bnt-shape {
  border-radius: 50%;
}
.hide-search {
  display: none;
}

/* Responsive Design Tablet */
@media screen and (max-width: 61.94em) {
  /*991px*/
  .searchHeader {
    display: none;
  }

  .navLinkLogInHeader {
    margin-top: 3rem;
  }
  .navLinkHeader,
  .navLinkLogInHeader {
    margin-left: 1rem;
  }
}

/* Responsive Design Mobile */
@media screen and (max-width: 37.5em) {
  /*600px*/
}
