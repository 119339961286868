.circle-img-wrapper {
  width: 10rem; /* Adjust the size according to your preference */
  height: 10rem;
  overflow: hidden;
  border-radius: 50%;
  margin: 1rem auto;
}

.circle-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h1 {
  display: flex;
  justify-content: center;
}

.custom-card .card-text {
  height: 6.25rem; /* Adjust the height as per your requirements */
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-body {
  height: 15.5rem; /* Adjust the height as per your requirement */
  overflow: hidden;
}

.card-body.expanded {
  height: auto;
}

.custom-card .card-text {
  text-align: justify;
  text-justify: inter-word;
}

.custom-card .card-text.expanded {
  height: auto;
  overflow: visible;
  text-overflow: initial;
}

.custom-link-button {
  color: var(--color-txt);
}