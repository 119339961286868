.resetPasswordContainer {
  min-height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetPasswordBox {
  width: 20rem;
  height: 30rem;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--color-white);
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.resetPasswordHeading {
  text-align: center;
  color: var(--color-txt);
  font-size: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-grey);
  width: 50%;
  margin: auto;
  font-weight: 400;
}

.resetPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 1.5rem;
  justify-content: space-evenly;
  height: 85%;
  transition: all 0.5s;
}

.resetPasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.resetPasswordForm > div > svg {
  position: absolute;
  transform: translateX(0.8rem);
  font-size: 1.6rem;
  color: var(--color-icon-gray);
}

.resetPasswordBtn {
  border: none;
  background-color: var(--color-action-btn);
  color: var(--color-white);
  width: 100%;
  box-shadow: 0 2px 5px var(--color-grey);
  cursor: pointer;
}

.resetPasswordBtn:hover,
.resetPasswordBtn:focus {
  background-color: var(--color-action-btn-hover);
  box-shadow: 0 2px 5px var(--color-grey);
  color: var(--color-white);
  cursor: pointer;
}
.resetPasswordBtn:active {
  background-color: var(--color-action-btn-hover) !important;
  color: var(--color-white) !important;
  transform: scale(0.98);
  box-shadow: 0 2px 5px var(--color-grey);
}
