.donation-success-body {
  /* background-color: var(--color-background); */
  /* display: flex;
  flex-direction: column; */
  color: var(--color-txt);
}
.donation-success-header {
  color: var(--color-txt);
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.donation-success-container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  /* width: 100%; */
  justify-content: center;
  flex-wrap: wrap;
  max-width: 50%;
  margin: auto;
}
/* .donation-success-container {
  margin-bottom: 2rem;
  background-color: var(--color-grey-background);
  border-radius: 1rem;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
} */
.img-page-donation-success {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.438rem 0.438rem 0 0;
}
.card-body {
  padding: 2rem;
}
.card-body-btn {
  margin-bottom: 1rem;
}

.card-body-h2 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: var(--color-txt);
  text-align: center;
}

.card-body-text {
  padding-inline: 1rem;
  text-align: justify;
}
