.background-container {
  background-image: url('../../../images/contactPage.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.background-container > div {
  margin-left: 2% !important;
  margin-top: -15% !important;
  position: absolute !important;
  left: 0 !important;
}

.contact-card {
  width: 20rem !important;
  height: 25rem !important;
  color: var(--color-txt);
}

.contact-group-list {
  border-radius: 0%;
  --bs-list-group-bg: var(--color-background);
  --bs-list-group-border-width: 0px;
}

.contact-card-header {
  background-color: var(--color-background);
  text-align: center;
}

.icon-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.list-group-item {
  color: var(--color-txt);
}
/* Responsive Design Tablet */
@media screen and (max-width: 61.94em) {
  /*991px*/
}

/* Responsive Design Mobile */
@media screen and (max-width: 37.5em) {
  /*600px*/
  .background-container {
    background-image: none;
    align-items: flex-start;
  }
  .background-container > div {
    margin-right: auto;
    margin-left: auto !important;
    margin-top: 8rem !important;
    position: relative !important;
  }
  .contact-card {
    min-width: 60%;
    color: var(--color-txt);
  }
}
