.footer-color {
  background-color: var(--color-header-footer);
}
.footer {
  height: 4rem;
}

.footer-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.footer-link a {
  color: var(--color-white);
  text-decoration: none;
}

.footer-link a:hover {
  color: var(--color-grey);
  cursor: pointer;
  text-decoration: none;
  transition: color 0.15s ease-in-out;
}

.footer-copyright-text {
  color: var(--color-grey);
}
