.not-found-body {
  background-color: var(--color-background);
}
.not-found-header {
  color: var(--color-txt);
  display: flex;
  justify-content: center;
}

.not-found-page-container {
  display: flex;
  /* Horizontal centering */
  justify-content: center;
  /* Vertical centering */
  align-items: center;
}

.not-found-wrapper div {
  background-color: var(--color-beige);
  max-width: 37.5rem;
  border-radius: 0.938rem;
  padding: 0.938rem;
  margin: 1.25rem;
}

.not-found-wrapper h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.625rem;
  color: var(--color-txt);
}

.img-page-not-found {
  width: 100%;
  height: auto;
  object-fit: cover;
}
