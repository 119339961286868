.home_header {
  position: absolute;
  top: 0;
  width: auto;
  justify-content: center;
}

.home_banner {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 40rem;
  margin-bottom: 1rem;
}
video {
  width: 100%;
  height: 40rem;
  object-fit: cover;
}
.home_banner_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--color-white);
  padding-left: 4rem;
  background-color: var(--color-transparent);
  gap: 1rem;
}
.home_banner_content h1 {
  font-size: 50;
  font-weight: 300;
}
.cardsContainer {
  padding: 1rem 0;
}
.grid_item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homePage_txt {
  text-align: center;
  color: var(--color-txt);
}
.find_pet_button button {
  background-color: var(--color-action-btn);
  border-radius: 18px;
  border: none;
  padding: 0.65rem;
  box-shadow: 0 2px 5px var(--color-gray);
}
.find_pet_button button:hover,
.find_pet_button button:focus {
  background-color: var(--color-action-btn-hover);
  box-shadow: 0 2px 5px var(--color-gray);
}
.find_pet_button button:active,
.find_pet_button button:visited {
  background-color: var(--color-action-btn-hover) !important;
  transform: scale(0.98);
  box-shadow: 0 2px 5px var(--color-gray);
}
.custom-pagination-item > a {
  background-color: var(--color-action-btn) !important;
  color: var(--color-white);
  border: none;
}

.custom-pagination-item > a:hover {
  color: var(--color-white);
}
.custom-pagination-item > span {
  background-color: var(--color-btn) !important;
  border: none;
}

/* Responsive Design Tablet */
@media screen and (max-width: 61.94em) {
  /*991px*/
}

/* Responsive Design Mobile */
@media screen and (max-width: 37.5em) {
  /*600px*/
  .home_banner_content {
    height: 5rem;
    position: relative;
    align-items: center;
    padding-left: 0;
    gap: 1rem;
  }
  .home_banner_content h1 {
    color: var(--color-txt);
  }

  video {
    display: none;
  }

  .home_banner {
    height: fit-content;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .cardsContainer {
    margin-inline: 1rem;
  }
}
