.donation-header {
  color: var(--color-txt);
  display: flex;
  justify-content: center;
  color: var(--color-txt);
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.main-donation-box {
  display: flex;
  /* Vertical centering */
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  color: var(--color-txt);
  min-width: 80%;
  margin-bottom: 2rem;
  width: 100%;
}
.donation-text-container {
  background-color: var(--color-grey-background);
  max-width: 28rem;
  border-radius: 0.438rem;
  display: inline-block;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
}
.card-text-img img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: 0.438rem 0.438rem 0 0;
}
.card-body-text {
  padding: 0.938rem;
  text-align: justify;
  margin-bottom: 1rem;
}
.card-body-text h2 {
  margin-bottom: 1rem;
}
.donation-container {
  display: inline-block;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--color-grey-background);
  align-items: center;
  max-width: 28rem;
  border-radius: 0.438rem;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
}
.card-body {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
}
.donation-card-body-header {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 1rem;
}
.donation-h2 {
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0;
  color: var(--color-txt);
}
.donation-h3 {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.first-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.second-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
}
.selectAmount-btn {
  background-color: var(--color-white);
  border: 1px solid darkgray;
  border-radius: 0.938rem;
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  width: 7rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--color-black);
  box-shadow: 0 2px 5px var(--color-grey);
  cursor: pointer;
  transition: box-shadow 1s;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
}

.selectAmount-btn:hover,
.selectAmount-btn:focus {
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
  transform: scale3d(1.01, 1.01, 1.01);
  border: 1px solid darkgray;
  background-color: var(--color-white);
  color: var(--color-black);
  cursor: pointer;
}

.selectAmount-btn:active,
.selectAmount-btn:visited {
  background-color: var(--color-white);
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
  transform: scale3d(1.01, 1.01, 1.01);
  border: 1px solid darkgray;
  color: var(--color-black) !important;
}

.amountSubmitForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s;
  margin-bottom: 1rem;
  margin-top: 1rem;
  gap: 1rem;
}
.amountSubmitForm > div {
  display: flex;
  max-width: 65%;
  align-items: center;
  border-radius: 0.938rem;
}

.amountSubmitForm > div > svg {
  position: absolute;
  transform: translateX(0.8rem);
  font-size: 1.2rem;
  color: var(--color-icon-gray);
  margin-left: 0.6rem;
}
.custom-amount > input {
  border-radius: 0.938rem;
  background-color: var(--color-white);
  border: 1px solid darkgray;
  outline-color: var(--color-header-footer);
  width: 100%;
  min-height: 100%;
  font-size: 1.3rem;
  padding: 0.4rem 0.5rem 0.4rem 2.5rem;
}
.donation-card-body-text {
  padding: 0.938rem;
  text-align: justify;
}

/* Responsive Design Tablet */
@media screen and (max-width: 61.94em) {
  /*991px*/
}
/* Responsive Design Mobile */
@media screen and (max-width: 46.375em) {
  /*742px*/
  .main-donation-box {
    flex-direction: column-reverse;
    align-items: center;
  }
}

/* Responsive Design Mobile */
@media screen and (max-width: 37.5em) {
  /*600px*/
}
