.LoginSignUpContainer {
  min-height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginSignUpBox {
  width: 20rem;
  height: 30rem;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--color-white);
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.ContainerWithButtons {
  display: flex;
  flex-direction: column;
}
.login_signUp_toggle {
  display: flex;
  min-height: 3rem;
  margin-bottom: 0;
}

.login_signUp_toggle > p {
  color: var(--color-txt);
  transition: all 0.5s;
  cursor: pointer;
  background-color: var(--color-beige);
  display: grid;
  padding-top: 0.1rem;
  place-items: center;
  width: 100%;
  margin-bottom: 0;
}

.login_signUp_toggle > p:hover {
  color: var(--color-action-btn);
}

.LoginSignUpBox > div > button {
  background-color: var(--color-action-btn);
  height: 3px;
  width: 50%;
  border: none;
  transition: all 0.5s;
  margin: 0;
}

.loginForm,
.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 1.5rem;
  justify-content: space-evenly;
  height: 85%;
  transition: all 0.5s;
}

.signUpForm {
  transform: translateY(-100%) translateX(-100%);
}

.loginForm > div,
.signUpForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.loginForm > div > svg,
.signUpForm > div > svg {
  position: absolute;
  transform: translateX(0.8rem);
  font-size: 1.6rem;
  color: var(--color-icon-gray);
}

.loginForm > a {
  text-decoration: none;
  align-self: flex-end;
  transition: all 0.5s;
  color: var(--color-txt);
}

.loginForm > a:hover {
  color: var(--color-action-btn);
}

.registerImage > img {
  width: 3rem;
  height: auto;
  border-radius: 100%;
}

.registerImage > input {
  display: flex;
  padding: 0%;
}

.registerImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 2rem;
  border: none;
  margin: 0%;
  font-size: 1rem;
  transition: all 0.5s;
  padding: 0 0.5rem;
  border-radius: 4px;
  color: var(--color-white);
  background-color: var(--color-btn);
}

.registerImage > input::file-selector-button:hover {
  background-color: var(--color-btn-hover);
}

.loginBtn,
.signUpBtn {
  border: none;
  background-color: var(--color-action-btn);
  color: var(--color-white);
  width: 100%;
  box-shadow: 0 2px 5px var(--color-grey);
  cursor: pointer;
}

.loginBtn:hover,
.signUpBtn:hover,
.loginBtn:focus,
.signUpBtn:focus {
  background-color: var(--color-action-btn-hover);
  box-shadow: 0 2px 5px var(--color-grey);
  color: var(--color-white);
  cursor: pointer;
}

.loginBtn:active,
.signUpBtn:active,
.loginBtn:visited,
.signUpBtn:visited {
  background-color: var(--color-action-btn-hover) !important;
  color: var(--color-white) !important;
  transform: scale(0.98);
  box-shadow: 0 2px 5px var(--color-grey);
}

.shiftToLeft {
  transform: translate(-100%);
}

.shiftToNeutralForm {
  transform: translateX(0%) translateY(-100%);
}

.shiftToNeutral {
  transform: translateX(0%);
}

.shiftToRight {
  transform: translateX(100%);
}
