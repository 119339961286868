/* Google Fonts - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

:root {
  --color-background: #fffffa;
  --color-txt: #333138;
  --color-btn: #d83c56;
  --color-btn-hover: #fa9da6;
  --color-btn-active: #d7576c;
  --color-btn-disabled: #fa9da6;
  --color-search-btn: #d90429;
  --color-search-btn-hover: #fa7885;
  --color-action-btn: #d90429;
  --color-action-btn-hover: #fa7885;
  --color-action-btn-active: #f55262;
  --color-header-footer: #333138;
  --color-white: #fff;
  --color-black: #000;
  --color-beige: #f3dedb;
  --color-transparent: transparent;
  --color-grey: #ffffff8c;
  --color-icon-gray: #747171;
  --color-grey-background: #eae9e9;
  --color-btn-nofavorites: #f0463a;
  --color-btn-nofavorites-hover: #ea726a;
  --color-btn-nofavorites-active: #ee554a;
}

main {
  flex: 1;
}

/*The CSS class "sr-only" is used to hide element visually from
sighted users while still making them accessible to assistive
technologies such as screen readers (SR), making website more 
inclusive for all users. That's why the name of the class is 
"sr-only", which means "for Screen Readers only". 
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

html {
  font-size: 100%;
}

/* Responsive Design Tablet */

@media screen and (max-width: 61.94em) {
  html {
    /*width < 991px*/
    font-size: 80%;
  }
}

/* Responsive Design Mobile */
@media screen and (max-width: 37.5em) {
  html {
    /*600px*/
    font-size: 70%;
  }
}

/* Responsive Design Mobile */
@media screen and (max-width: 22.5em) {
  html {
    /*360*/
    font-size: 60%;
  }
}

/* Responsive Design Mobile */
@media screen and (max-width: 18.75em) {
  html {
    /*300px*/
    font-size: 50%;
  }
}
