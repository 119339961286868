.con-like {
  color: var(--color-action-btn);
  position: relative;
  width: 50px;
  height: 50px;
}

.con-like .like {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.con-like .checkmark {
  transform: scale(0.7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.con-like .outline,
.con-like .filled {
  fill: var(--color-action-btn);
  position: absolute;
}

.con-like .filled {
  animation: kfr-filled 0.5s;
  display: none;
}

.con-like .celebrate {
  position: absolute;
  animation: kfr-celebrate 0.5s;
  animation-fill-mode: forwards;
  display: none;
}

.con-like .poly {
  stroke: var(--color-action-btn);
  fill: var(--color-action-btn);
}

.con-like .like:checked ~ .checkmark .filled {
  display: block;
}

.con-like .like:checked ~ .checkmark .celebrate {
  display: block;
}

@keyframes kfr-filled {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes kfr-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    display: none;
  }
}

@media screen and (max-width: 37.5em) {
  /*600px*/
  .con-like {
    transform: scale(0.8);
  }
}
/* Responsive Design Mobile */
@media screen and (max-width: 18.75em) {
  .con-like {
    transform: scale(0.6);
  }
}
