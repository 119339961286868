.gradient-custom {
  background-image: linear-gradient(
    to left top,
    #ff3264,
    #fd466b,
    #fb5571,
    #f96278,
    #f66e7f,
    #f67c87,
    #f68a90,
    #f59799,
    #f6a9a7,
    #f6bbb7,
    #f5cdc8,
    #f3dedb
  );
}
.color-btn-edit-prof {
  background-color: var(--color-white);
  color: var(--color-action-btn);
}
.color-btn-edit-prof:hover,
.color-btn-edit-prof:focus {
  background-color: var(--color-white);
  color: var(--color-action-btn-hover);
}
.color-btn-edit-prof:active,
.color-btn-edit-prof:visited {
  transform: scale(0.98);
  background-color: var(--color-white);
  color: var(--color-action-btn-hover);
}

.color-btn {
  background-color: var(--color-btn);
  color: var(--color-white);
}
.color-btn:hover,
.color-btn:focus {
  background-color: var(--color-btn-hover);
  color: var(--color-white);
}

.color-btn:active,
.color-btn:visited {
  transform: scale(0.98);
  background-color: var(--color-btn-hover) !important;
  color: var(--color-white) !important;
}

.bnt-shape {
  border-radius: 50%;
}

.profile-container {
  min-width: 38rem;
  max-width: 70rem;
  padding-bottom: 5%;
  overflow: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.profile-heading-wrapper {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.profile-heading {
  font-weight: 400;
}
